import gql from 'graphql-tag'

// export const SIGN_IN = gql`
//   mutation SignIn($username: String!, $password: String!) {
//     signIn(username: $username, password: $password) {
//       id
//       email
//       run
//       dv
//       username
//       name
//       surname_1
//       surname_2
//       sex
//       id_role
//       id_laboratory
//       id_client
//       id_site
//       laboratories
//       clients
//       sites
//       reset_password
//       active
//       laboratory
//     }
//   }
// `

export const SIGN_IN = gql`
  mutation SignIn($username: String!, $password: String!, $tries: Int!) {
    signIn(username: $username, password: $password, tries: $tries) {
      data
      error {
        code
        message
      }
    }
  }
`
