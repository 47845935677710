import React from 'react'
import { Row, Col, Form, Accordion, Card, Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

// Hooks
import {
  useHistory,
  useQuery,
  useState,
  useMutation,
  useGlobal,
} from '../../../../../hooks'

// Components
import UIButton from '../../../../UI/Button'
import UILoading from '../../../../UI/Loading'
import UIError from '../../../../UI/Error'
import UIForm from '../../../../UI/FormReload'
import UIFormInputInteger from '../../../../UI/FormReload/Input/Integer'
import UIFormInputSelect from '../../../../UI/FormReload/Input/Select'
import UIFormInputTextarea from '../../../../UI/FormReload/Input/Textarea'
import UIFormSeparation from '../../../../UI/FormReload/Separation'
import UITable from '../../../../UI/Table'
import UITableHead from '../../../../UI/Table/Head'
import UITableBody from '../../../../UI/Table/Body'

// Queries
import { GET_SHIFT_INFO, ENTER_SHIFT_CHANGE } from './queries'

const TribologySamplesShiftChangeForm = ({ queries }) => {
  const [{ me }] = useGlobal()
  const history = useHistory()

  const id_role = queries.get('id_role')
  const date_from = queries.get('date_from')
  const date_to = queries.get('date_to')

  const [manuals, setManualsState] = useState([])

  // Order is important, since they're stored as ID in the database (i.e. 1 is Spectroil, 2 is Viscosímetro, etc.)
  const lab_machines_names = {
    antofagasta: [
      'Spectroil M',
      'Viscosímetro automático',
      null, // 'LaserNet Fines (LNF-C)',
      'Contador de partículas LNF',
      'Titulador Potenciómetro',
      null, // 'Titulador Potenciómetro (CMDIC)',
      'PQ Analex paL',
      'FT IR PerkinElmer',
      'Medidor de dilución de combustible',
      'Microscopio',
      'Placa calefactora/Motor calefactor',
      'Agitador de muestras',
      'Medidor de pH/Refractometro',
    ],
    collahuasi: [
      'Spectroil M',
      'Viscosímetro automático',
      null, // 'LaserNet Fines (LNF-C)',
      'Contador de partículas LNF',
      null, //'Titulador Potenciómetro',
      null, // 'Titulador Potenciómetro (CMDIC)',
      'PQ Poseidon',
      'FT IR Bruker',
      'Medidor de dilución de combustible',
      'Microscopio',
      'Placa calefactora/Motor calefactor',
      'Agitador de muestras',
    ],
    centinela: [
      'Spectroil M',
      'Viscosímetro automático Mini AV',
      null, // 'LaserNet Fines (LNF-C)',
      'Contador de partículas LNF',
      null, // 'Titulador Potenciómetro',
      null, // 'Titulador Potenciómetro (CMDIC)',
      'PQ Analex pqL',
      'FT IR (Fluid Scan)',
      'Medidor de dilución de combustible',
      'Microscopio',
      'Coolcheck',
      'Agitador de muestras',
      'Kit de filtración al vacío',
    ],
    quebradablanca: [
      'Spectroil 100',
      'Viscosímetro MiniVisc',
      null, // 'LaserNet Fines (LNF-C)',
      'Contador de partículas LNF',
      null, // 'Titulador Potenciómetro',
      null, // 'Titulador Potenciómetro (CMDIC)',
      'PQ Ferrocheck',
      'FT IR (Fluid Scan)',
      'Medidor de dilución de combustible',
      'Microscopio',
      'Coolcheck',
      'Viscosímetro SimpleVisc',
    ],
    esmax: [
      'Spectroil M',
      'Viscosímetro automático',
      null, // 'LaserNet Fines (LNF-C)',
      'Contador de partículas LNF',
      null, // 'Titulador Potenciómetro',
      null, // 'Titulador Potenciómetro (CMDIC)',
      'PQ',
      'FT IR',
      'Medidor de dilución de combustible',
      null, // 'Microscopio',
      'Placa calefactora/Motor calefactor',
      'Agitador de muestras',
      'Medidor de pH/Refractometro',
    ],
  }

  // ------------------------------------------------------------------------
  //
  // Query
  //
  // ------------------------------------------------------------------------

  const { data, loading } = useQuery(GET_SHIFT_INFO, {
    fetchPolicy: 'network-only',
    variables: { filters: { id_role, date_from, date_to } },
    onErrorMessage: `Error al intentar obtener información `,
    onSuccess: ({ data }) => {
      if (data && data.manuals) setManualsState(data.manuals)
      else {
        const length = (data && data.pending && data.pending.length) || 0
        setManualsState(Array(length).fill(0))
      }
    },
  })

  const [enterShiftChange] = useMutation(ENTER_SHIFT_CHANGE, {
    onSuccess: () => {
      history.push(
        `/tribology/samples/shift_change/view?id_role=${id_role}&date_from=${date_from}&date_to=${date_to}`,
      )
    },
  })

  if (loading) return <UILoading />

  if (data && data.t7ShiftChangeInfo && data.t7ShiftChangeInfo.data) {
    const { counts, names, comments, pending } = data.t7ShiftChangeInfo.data

    const handleBack = () => {
      history.push(`/tribology/samples/shift_change/filter`)
    }

    const handleManualChange = (index) => {
      return (e) =>
        setManualsState((prev) => {
          const value = e.target.value
          prev[index] =
            [null, undefined, ''].includes(value) || isNaN(value)
              ? 0
              : parseInt(value)
          return [...prev]
        })
    }

    const handleSubmit = (values) => {
      const { comments, ...rest } = values

      const manuals_keys = Object.keys(rest).filter((key) =>
        /^manuals_[0-9]+$/.test(key),
      )

      const manuals = manuals_keys
        .reduce((p, key) => {
          const index = parseInt(key.split('_')[1])
          p[index] = rest[key]
          return p
        }, [])
        .join(';')

      const machines_keys = Object.keys(rest).filter((key) =>
        /^machines_(state|comment)_[0-9]+$/.test(key),
      )

      const machines = JSON.stringify(
        machines_keys.reduce((p, key) => {
          const [, state, index] = key.split('_')
          p[index] = { ...p[index], [state]: rest[key] }
          return { ...p }
        }, {}),
      )

      enterShiftChange({
        variables: {
          data: {
            comments,
            manuals,
            id_role,
            date_from,
            date_to,
            names: names.join(','),
            counts: counts
              .map(({ count }) => count)
              .reduce((p, count) => {
                count.forEach((c, index) => {
                  if (!p[index]) p[index] = []
                  p[index].push(c)
                })
                return p
              }, [])
              .map((c) => c.join(','))
              .join(';'),
            pending: pending.join(';'),
            machines,
          },
        },
      })
    }

    return (
      <UIForm onSubmit={handleSubmit}>
        <Form.Group as={Row} className="my-1">
          <Col className="d-flex">
            <UIButton className="mr-1" onClick={handleBack}>
              Atrás
            </UIButton>
            <UIButton type="submit">Generar PDF</UIButton>
          </Col>
        </Form.Group>
        <UIFormSeparation />
        <Row>
          <Col xs={12}>
            <Card className="text-center">
              <Card.Header>
                <h5>Turno</h5>
                <Badge className="p-2" variant="dark">
                  {moment(date_from, 'YYYY/MM/DD').format('DD-MM-YYYY')}
                </Badge>{' '}
                -{' '}
                <Badge className="p-2" variant="dark">
                  {moment(date_to, 'YYYY/MM/DD').format('DD-MM-YYYY')}
                </Badge>
              </Card.Header>
              <Card.Body>
                {names.map((name, index) => (
                  <Badge
                    className="p-2 mx-1"
                    variant="stng"
                    key={`names-${index}`}
                  >
                    {name}
                  </Badge>
                ))}
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12}>
            <UITable bordered className="mt-2">
              <UITableHead>
                {[3, '3'].includes(id_role)
                  ? ['Fecha', 'Muestras ensayadas']
                  : [
                      'Fecha',
                      'Muestras propuestas Antofagasta',
                      'Muestras propuestas Collahuasi',
                      'Muestras propuestas Quebrada Blanca',
                    ]}
              </UITableHead>
              <UITableBody>
                {counts.map(({ count, date }, index) => (
                  <tr key={`counts-${index}`}>
                    <td>{date}</td>
                    {count.map((c, index) => (
                      <td key={`count-2-${index}`}>{c}</td>
                    ))}
                  </tr>
                ))}
                <tr
                  className="table-secondary table-borderless"
                  style={{ borderTop: '1px solid #CBCBCB' }}
                >
                  <td>Muestras manuales</td>
                  {manuals.map((manual, index) => (
                    <td key={`manuals-${index}`}>
                      <UIFormInputInteger
                        name={`manuals_${index}`}
                        required
                        style={{ maxWidth: '100px' }}
                        className="mx-auto text-center"
                        value={manual}
                        onChange={handleManualChange(index)}
                      />
                    </td>
                  ))}
                </tr>
                <tr
                  className="table-secondary table-borderless"
                  style={{ borderTop: '2px dashed #BBBBBB' }}
                >
                  <td>Total</td>
                  {counts
                    .reduce((p, { count }) => {
                      if (!p.length) p = Array(count.length).fill(0)
                      return count.map((c, index) => p[index] + c)
                    }, manuals)
                    .map((c, index) => (
                      <td key={`count-${index}`}>{c}</td>
                    ))}
                </tr>
                <tr
                  className="table-secondary table-borderless"
                  style={{ borderTop: '2px dashed #BBBBBB' }}
                >
                  <td>Pendientes</td>
                  {pending.map((p, index) => (
                    <td key={`pending-${index}`}>{p}</td>
                  ))}
                </tr>
              </UITableBody>
            </UITable>
          </Col>
          {[3, '3'].includes(id_role) &&
            lab_machines_names[me.laboratory].length > 0 && (
              <Col xs={12}>
                <UIFormSeparation text="Estado de equipos" />
                <UITable bordered className="mt-2">
                  <UITableHead>
                    {['Equipo', 'Estado', 'Observaciones']}
                  </UITableHead>
                  <UITableBody>
                    {lab_machines_names[me.laboratory].map((title, index) => {
                      if (title === null) return <></>

                      return (
                        <tr key={'equipo-' + index}>
                          <td>{title}</td>
                          <td>
                            <UIFormInputSelect
                              name={`machines_state_${index}`}
                              initialValue={'1'}
                            >
                              <option value={'1'}>Operativo</option>
                              <option value={'2'}>Fuera de Servicio</option>
                            </UIFormInputSelect>
                          </td>
                          <td>
                            <UIFormInputTextarea
                              name={`machines_comment_${index}`}
                              rows={1}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </UITableBody>
                </UITable>
              </Col>
            )}
          <Col xs={12}>
            <UIFormSeparation text="Registro de actividades" />
            <UIFormInputTextarea
              name="comments"
              rows={10}
              initialValue={comments || ''}
            />
          </Col>
        </Row>
      </UIForm>
    )
  }

  return <UIError />
}

export default TribologySamplesShiftChangeForm
